import ErrorBoundary from './components/ErrorBoundary';
import ImageCreationGallery from './components/ImageCreationGallery';

function App() {
  return (
    <ErrorBoundary>
      <ImageCreationGallery />
    </ErrorBoundary>
  );
}

export default App; 