import React, { useState, useEffect } from 'react';
import { Search, Info, X, Settings, Upload, Key, Settings2, ChevronDown, Camera, Layout, Circle, ArrowRightFromLine } from 'lucide-react';
import logo from './logo.png';
import logoblack from './logoblack.png';
import logobackground from './logobackground.png';
import loading from './loading.gif';

const models = {
  standard: [
    'DALL-E',
    'Midjourney',
    'Adobe Firefly',
    'stable-diffusion-xl-1024-v1-0',
    'black-forest-labs/FLUX.1-dev'
  ],
  synthesized: [
    'DALL-E-synthesizer',
    'Midjourney-synthesizer',
    'Adobe Firefly-synthesizer',
    'stable-diffusion-xl-1024-v1-0-synthesizer',
    'black-forest-labs/FLUX.1-dev-synthesizer'
  ]
};

const ImageCreationGallery = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);
  const [images, setImages] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showApiModal, setShowApiModal] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPrompts, setShowPrompts] = useState(false);
  const [removeDuplicates, setRemoveDuplicates] = useState(true);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [advancedFields, setAdvancedFields] = useState({
    modelName: '',
    keywords: '',
    prompts: '',
  });
  const [showFineTuned, setShowFineTuned] = useState(false);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedCameraOptions, setSelectedCameraOptions] = useState([]);

  const styleTags = [
    'Watercolor', 'Oil Painting', 'Digital Art', 'Pencil Sketch',
    'Anime Style', 'Photorealistic', 'Abstract', 'Minimalist',
    // ... add more style tags ...
  ];

  const cameraOptions = [
    'Canon EOS R5', 'Sony A7III', 'f/1.8 aperture', '4K resolution',
    'HDR', 'Long Exposure', 'Macro Lens', 'Wide Angle',
    // ... add more camera options ...
  ];

  const resolutionOptions = [
    { label: '1024x1024', value: '1024x1024' },
    { label: '512x512', value: '512x512' },
    { label: 'Instagram Post', value: '1080x1080' },
    { label: 'Facebook Post', value: '1200x630' },
    { label: 'Custom', value: 'custom' }
  ];

  useEffect(() => {
    fetchImages();
  }, []);

  // const getApiBaseUrl = () => {
  //   const hostname = window.location.hostname;
  //   const protocol = window.location.protocol; // Get current protocol
  //   const isTor = hostname.endsWith('.onion');
  //   const isLocalToNet = true;
    
  //   if (isTor) {
  //     return {
  //       fileServer: `http://${hostname}:3001`,
  //       generationApi: `http://${hostname}:8000`,
  //       staticFiles: `http://${hostname}:3001`
  //     };
  //   }

  //   if (isLocalToNet) {
  //     return {
  //       fileServer: 'https://xeno2.localto.net',
  //       generationApi: 'https://xeno3.localto.net',
  //       staticFiles: 'https://xeno2.localto.net'
  //     };
  //   }

  //   console.log("-----")
  //   console.log(protocol)
  //   console.log(hostname)
  //   console.log("-----")

  //   // Local development
  //   return {
  //     fileServer: `${protocol}//192.168.29.193:3001`,
  //     generationApi: `${protocol}//192.168.29.193:8000`,
  //     staticFiles: `${protocol}//192.168.29.193:3001`
  //   };
  // };
  

  const transformImagePath = (imagePath) => {
    if (!imagePath) return '';
    if (imagePath === loading) return imagePath; // Handle loading.gif
    
    // const baseUrl = getApiBaseUrl();
    
    // Extract the last component (image ID) from the path
    const imageId = imagePath.split('/').pop();
    
    // Construct the full URL using just the image ID
    return `${process.env.REACT_APP_API_FOR_STATIC_FILES_URL}/api/images/${imageId}.png`;
  };

  const fetchImages = async () => {
    try {
      //const baseUrl = getApiBaseUrl();
      const response = await fetch(`${process.env.REACT_APP_API_FOR_FILE_SERVER}/api/images`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        credentials: 'include',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      const transformedData = data.map(img => ({
        ...img,
        imagePath: transformImagePath(img.imagePath)
      }));
      
      setImages(transformedData);
    } catch (error) {
      console.error('Error fetching images:', error);
      setImages([]);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    // Validate search term and selected models
    if (!searchTerm.trim()) return;
    if (selectedModels.length === 0) {
      alert("Please select at least one model before generating");
      return;
    }

    // Enhance prompt with selected styles and camera options
    let enhancedPrompt = searchTerm;
    if (selectedStyles.length > 0) {
      enhancedPrompt += `, styles: ${selectedStyles.join(', ')}`;
    }
    if (selectedCameraOptions.length > 0) {
      enhancedPrompt += `, camera: ${selectedCameraOptions.join(', ')}`;
    }

    setIsGenerating(true);

    try {
      //const baseUrl = getApiBaseUrl();
      const placeholderImage = {
        id: 'generating',
        imagePath: loading,
        prompt: enhancedPrompt,
        model: selectedModels[0],
        status: 'generating'
      };
      setImages(prevImages => [placeholderImage, ...prevImages]);

      const response = await fetch(`${process.env.REACT_APP_API_FOR_GENERATION_API_URL}/api/generate`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          prompt: enhancedPrompt,
          models: selectedModels,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      const newImage = {
        id: data.id || Date.now().toString(),
        imagePath: transformImagePath(data.imagePath),
        prompt: enhancedPrompt,
        model: selectedModels[0],
        createdAt: new Date().toISOString(),
        status: 'completed'
      };
      
      setImages(prevImages => {
        const filteredImages = prevImages.filter(img => img.id !== 'generating');
        return [newImage, ...filteredImages];
      });
      
      setSearchTerm('');
    } catch (error) {
      console.error('Error generating image:', error);
      setImages(prevImages => prevImages.filter(img => img.id !== 'generating'));
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // Handle file upload logic here
  };

  const toggleModel = (model, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    setSelectedModels(prevModels => 
      prevModels.includes(model)
        ? prevModels.filter(m => m !== model)
        : [...prevModels, model]
    );
  };
  
  const filteredImages = images
    .filter(img =>
      img.prompt.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedModels.length === 0 || selectedModels.includes(img.model))
    )
    .reduce((unique, image) => {
      if (!removeDuplicates) {
        return [...unique, image];
      }
      
      const isDuplicate = unique.some(existingImage => {
        const isSamePrompt = existingImage.prompt.toLowerCase() === image.prompt.toLowerCase();
        const isSameModel = existingImage.model === image.model;
        
        // Check if the image is from Midjourney and has an underscore in the ID
        const isMidjourneyWithUnderscore = image.model === 'Midjourney' && image.id.includes('_');
        
        // Only consider it a duplicate if it's not a Midjourney image with an underscore
        return isSamePrompt && isSameModel && (!isMidjourneyWithUnderscore || !existingImage.id.includes('_'));
      });
      
      // Only add Midjourney images with underscores to the unique list
      if (image.model === 'Midjourney' && !image.id.includes('_')) {
        return unique;
      }
      
      return isDuplicate ? unique : [...unique, image];
    }, [])
    .sort((a, b) => {
      // Handle generating status - always show at top
      if (a.status === 'generating') return -1;
      if (b.status === 'generating') return 1;
      
      // Sort by creation date (newest first)
      const dateA = new Date(a.createdAt || 0);
      const dateB = new Date(b.createdAt || 0);
      return dateB - dateA;
    });
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <nav 
        className="bg-cover bg-center shadow-sm" 
        style={{ backgroundImage: `url(${logobackground})` }}
      >
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <img src={logoblack} alt="XenoGen" className="h-8 w-auto" />
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => setShowApiModal(true)}
              className="flex items-center px-4 py-2 text-gray-700 bg-white/80 hover:bg-white/90 rounded-lg backdrop-blur-sm transition-colors"
            >
              <Key className="h-5 w-5 mr-2" />
              API
            </button>
            <button 
              onClick={() => setShowSettings(true)}
              className="p-2 bg-white/80 hover:bg-white/90 rounded-full backdrop-blur-sm transition-colors"
            >
              <Settings2 className="h-5 w-5" />
            </button>
          </div>
        </div>
      </nav>

      <div className="container mx-auto px-2 py-8">
        <form onSubmit={handleSearch} className="mb-8">
          <div className="flex mb-6">
            <input
              type="text"
              placeholder="Search images or enter prompt to generate"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow px-6 py-3 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button 
              type="submit"
              disabled={isGenerating}
              className="px-6 py-3 bg-[#3DB4BB] text-white rounded-r-lg hover:bg-[#35999f] focus:outline-none focus:ring-2 focus:ring-[#3DB4BB] transition-colors"
            >
              {isGenerating ? 'Generating...' : 'Generate'}
            </button>
          </div>
          <div className="flex flex-wrap gap-2 mb-4">
            {models.standard.map(model => (
              <button
                key={model}
                type="button"
                onClick={(e) => toggleModel(model, e)}
                className={`px-4 py-2 rounded-lg text-sm ${
                  selectedModels.includes(model)
                    ? 'bg-[#3DB4BB] text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                } transition-colors`}
              >
                {model}
              </button>
            ))}
          </div>
          
          {/* Synthesizer Models */}
          <div className="w-full mt-3">
            <h4 className="text-sm font-medium text-gray-500 mb-2">Synthesizer Models</h4>
            <div className="flex flex-wrap gap-3">
              {models.synthesized.map(model => (
                <button
                  key={model}
                  type="button"
                  onClick={(e) => toggleModel(model, e)}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                    selectedModels.includes(model)
                      ? 'bg-purple-500 text-white hover:bg-purple-600'
                      : 'bg-gray-50 text-gray-700 border border-gray-300 hover:bg-gray-100'
                  }`}
                >
                  {model}
                </button>
              ))}
            </div>
          </div>
        </form>

        <div className="mt-4">
          <button
            onClick={() => setShowMoreOptions(!showMoreOptions)}
            className="flex items-center text-[#3F786B] hover:text-[#2d5a4f]"
          >
            <ChevronDown className={`h-4 w-4 mr-1 transform ${showMoreOptions ? 'rotate-180' : ''}`} />
            More Options
          </button>
          {showMoreOptions && (
  <div className="mt-4 p-4 bg-gray-50 rounded-lg space-y-4">
    {/* Styles Section */}
    <div>
      <h3 className="font-medium mb-2">Styles</h3>
      <div className="flex flex-wrap gap-2">
        {styleTags.map(style => (
          <button
            key={style}
            onClick={() => {
              setSelectedStyles(prev => 
                prev.includes(style)
                  ? prev.filter(s => s !== style)
                  : [...prev, style]
              );
            }}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
              selectedStyles.includes(style)
                ? 'bg-[#E56B36] text-white hover:bg-[#d15a2a] shadow-md transform scale-105'
                : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'
            }`}
          >
            {style}
          </button>
        ))}
      </div>
    </div>

    {/* Camera Section */}
    <div>
      <h3 className="font-medium mb-2">Camera</h3>
      <div className="flex flex-wrap gap-2">
        {cameraOptions.map(option => (
          <button
            key={option}
            onClick={() => {
              setSelectedCameraOptions(prev => 
                prev.includes(option)
                  ? prev.filter(o => o !== option)
                  : [...prev, option]
              );
            }}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
              selectedCameraOptions.includes(option)
                ? 'bg-[#E56B36] text-white hover:bg-[#d15a2a] shadow-md transform scale-105'
                : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'
            }`}
          >
            {option}
          </button>
        ))}
      </div>
    </div>

    {/* Rest of your More Options content */}
    <div>
      <h3 className="font-medium mb-2">Resolution</h3>
      <select className="form-select mt-1 block w-full">
        {resolutionOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      
      <div className="mt-2 flex gap-2">
        <input
          type="number"
          placeholder="Width"
          className="form-input w-24"
        />
        <span className="text-gray-500">×</span>
        <input
          type="number"
          placeholder="Height"
          className="form-input w-24"
        />
      </div>

      <div className="mt-2">
        <label className="block text-sm font-medium text-gray-700">
          Negative Prompt
        </label>
        <textarea
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          rows="3"
        />
      </div>
    </div>

    <div className="space-y-2">
      <div className="flex items-center text-gray-500">
        <Circle className="h-4 w-4 mr-2" />
        <span>AutoGen (Coming Soon)</span>
      </div>
      <div className="flex items-center text-gray-500">
        <Circle className="h-4 w-4 mr-2" />
        <span>Advertisement Overlay (Coming Soon)</span>
      </div>
      <div className="flex items-center text-gray-500">
        <ArrowRightFromLine className="h-4 w-4 mr-2" />
        <span>Add Models to Pipeline (Coming Soon)</span>
      </div>
    </div>
  </div>
)}
        </div>

        <div className="mt-8">
          <button
            onClick={() => setShowFineTuned(!showFineTuned)}
            className="flex items-center text-[#3F786B] hover:text-[#2d5a4f]"
          >
            <ChevronDown className={`h-4 w-4 mr-1 transform ${showFineTuned ? 'rotate-180' : ''}`} />
            Fine-tuned Models
          </button>

          {showFineTuned && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Model Name
                </label>
                <input
                  type="text"
                  value={advancedFields.modelName}
                  onChange={(e) => setAdvancedFields(prev => ({...prev, modelName: e.target.value}))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter model name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Keywords
                </label>
                <input
                  type="text"
                  value={advancedFields.keywords}
                  onChange={(e) => setAdvancedFields(prev => ({...prev, keywords: e.target.value}))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter keywords (comma separated)"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Prompts
                </label>
                <textarea
                  value={advancedFields.prompts}
                  onChange={(e) => setAdvancedFields(prev => ({...prev, prompts: e.target.value}))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter training prompts (one per line)"
                  rows="3"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Model Files (zip, png, jpg, webp)
                </label>
                <div className="flex items-center">
                  <label className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50">
                    <Upload className="h-4 w-4 mr-2" />
                    <span className="text-sm">Choose File</span>
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
              </div>

              <button
                onClick={() => {
                  console.log('Submitting:', advancedFields);
                }}
                className="w-full px-4 py-2 bg-[#3DB4BB] text-white rounded-lg hover:bg-[#35999f] transition-colors"
              >
                Submit Model
              </button>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
          {filteredImages.map(image => (
            <div 
              key={image.id} 
              className="group bg-white rounded-xl overflow-hidden cursor-pointer relative aspect-square hover:shadow-lg transition-shadow duration-200"
              onClick={() => setSelectedImage(image)}
            >
              <img
                src={image.image_url}
                alt={image.prompt}
                className="w-full h-full object-cover rounded-xl"
              />
              {showPrompts && (
                <div className="absolute bottom-0 left-0 right-0 bg-black/70 text-white p-3 translate-y-full transition-transform duration-200 ease-in-out group-hover:translate-y-0 rounded-b-xl">
                  <p className="text-sm">{image.prompt}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        {selectedImage && (
          <div className="fixed inset-0 bg-black/95 flex items-center justify-center p-4 z-50">
            <div className="relative bg-white rounded-xl w-[90%] h-[90vh] mx-auto overflow-hidden flex flex-col">
              <div className="absolute top-4 right-4 flex gap-2 z-10">
                <button 
                  onClick={() => setShowDetails(!showDetails)}
                  className="p-2 bg-white/90 text-gray-800 rounded-full hover:bg-white focus:outline-none transition-colors"
                >
                  <Info className="h-5 w-5" />
                </button>
                <button 
                  onClick={() => {
                    setSelectedImage(null);
                    setIsFullScreen(false);
                    setShowDetails(false);
                  }}
                  className="p-2 bg-white/90 text-gray-800 rounded-full hover:bg-white focus:outline-none transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              
              <div className="relative flex-1 min-h-0" onClick={() => setIsFullScreen(!isFullScreen)}>
                <img 
                  src={selectedImage.image_url} 
                  alt={selectedImage.prompt} 
                  className={`cursor-zoom-in w-full h-full ${
                    isFullScreen 
                      ? 'fixed inset-0 w-screen h-screen object-contain z-50 bg-black' 
                      : 'object-contain'
                  }`}
                />
              </div>

              {showDetails && (
                <div className="absolute right-0 top-0 h-full w-80 bg-white p-6 shadow-lg overflow-y-auto">
                  <h3 className="text-xl font-semibold mb-4">Image Details</h3>
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium text-gray-900">ID</h4>
                      <p className="text-gray-700 mt-1">{selectedImage.id}</p>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900">Prompt</h4>
                      <p className="text-gray-700 mt-1">{selectedImage.prompt}</p>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900">Model</h4>
                      <p className="text-gray-700 mt-1">{selectedImage.model}</p>
                    </div>
                    {selectedImage.createdAt && (
                      <div>
                        <h4 className="font-medium text-gray-900">Created</h4>
                        <p className="text-gray-700 mt-1">
                          {new Date(selectedImage.createdAt).toLocaleString()}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {showApiModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
              <h3 className="text-xl font-semibold mb-4">API Key Configuration (Coming Soon)</h3>
              <input
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="Enter your API key"
                className="w-full px-4 py-2 border rounded-lg mb-4"
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowApiModal(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    // Save API key logic here
                    setShowApiModal(false);
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {showSettings && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold">Settings</h3>
                <button
                  onClick={() => setShowSettings(false)}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>

              <div className="space-y-6">
                {/* General Settings */}
                <div>
                  <h4 className="font-medium mb-3">General Settings</h4>
                  <div className="space-y-3">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={removeDuplicates}
                        onChange={(e) => setRemoveDuplicates(e.target.checked)}
                        className="form-checkbox"
                      />
                      <span>Remove duplicate images</span>
                    </label>

                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={showPrompts}
                        onChange={(e) => setShowPrompts(e.target.checked)}
                        className="form-checkbox"
                      />
                      <span>Show prompts under images</span>
                    </label>
                  </div>
                </div>

                {/* Model Settings */}
                <div>
                  <h4 className="font-medium mb-3">Model Settings</h4>
                  <div className="space-y-3">
                    {/* Standard Models */}
                    <div className="space-y-3">
                      <h5 className="text-sm font-medium text-gray-500">Standard Models</h5>
                      {models.standard.map(model => (
                        <div key={model} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                          <span>{model}</span>
                          <div className="space-x-2">
                            <button className="px-3 py-1 text-sm bg-blue-100 text-blue-600 rounded hover:bg-blue-200">
                              Configure
                            </button>
                            <button className="px-3 py-1 text-sm bg-gray-100 text-gray-600 rounded hover:bg-gray-200">
                              Disable
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    
                    {/* Synthesizer Models */}
                    <div className="space-y-3 mt-6">
                      <h5 className="text-sm font-medium text-gray-500">Synthesizer Models</h5>
                      {models.synthesized.map(model => (
                        <div key={model} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                          <span>{model}</span>
                          <div className="space-x-2">
                            <button className="px-3 py-1 text-sm bg-purple-100 text-purple-600 rounded hover:bg-purple-200">
                              Configure
                            </button>
                            <button className="px-3 py-1 text-sm bg-gray-100 text-gray-600 rounded hover:bg-gray-200">
                              Disable
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Advanced Settings */}
                <div>
                  <h4 className="font-medium mb-3">Advanced Settings</h4>
                  <div className="space-y-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Default Resolution
                      </label>
                      <select className="form-select w-full">
                        {resolutionOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Image Quality
                      </label>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        defaultValue="80"
                        className="w-full"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Cache Duration (hours)
                      </label>
                      <input
                        type="number"
                        defaultValue="24"
                        className="form-input w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex justify-end gap-2">
                <button
                  onClick={() => setShowSettings(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    // Save settings logic here
                    setShowSettings(false);
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCreationGallery;
